/* ============================================
   Colors
============================================= */

/* Brand Colors */
$color-primary-main: #006AC6;
$color-primary-dark: #0084D5;

$color-secondary-main: #ff9e1b;
$color-secondary-light: #FFB600;

$color-blue-lighter: #DFFDFC;
$color-blue-light: #71EEFB;
$color-blue-main: #57BBE6;
$color-blue-dark: #4036D3;
$color-blue-10: #DFFDFC;
$color-blue-20: #71EEFB;
$color-blue-30: #57BBE6;
$color-blue-40: #4036D3;
$color-blue-50: #2B0F9F;

$color-purple-lighter: #D5A4F9;
$color-purple-light: #A76FF0;
$color-purple-main: #3B0688;
$color-purple-dark: #180163;
$color-purple-10: #D5A4F9;
$color-purple-20: #B276F0;
$color-purple-30: #3B0688;
$color-purple-40: #180163;

$color-pink-main: #FF069B;
$color-pink-light: #FFB6E0;
$color-pink-dark: #FF069B;
$color-pink-10: #F4B5EA;
$color-pink-20: #E95CC6;
$color-pink-30: #D82EA1;
$color-pink-40: #8419DC;

$color-danger-light: #f97171;
$color-danger-main: #FC5050;
$color-danger-dark: #F63131;

$color-error-10: #FFFDFE;
$color-error-20: #FDDFE4;
$color-error-30: #F78192;
$color-error-40: #D00320;

$color-success-main: #00C853;
$color-success-dark: #00B248;
$color-success-light: #00E676;
$color-success-30: #6BBF59;

$color-green-main: #71A394;
$color-green-light: #A3D1C2;
$color-green-dark: #4D7C6A;

$color-yellow-10: #FBEE8A;
$color-yellow-20: #F9E550;
$color-yellow-30: #F09035;
$color-yellow-40: #9D1F28;

/* Neutral Colors */
$color-background: #fff;
$color-bold: #231F20;
$color-light: #fff;
$color-dark: #424242;
$color-navbar-guide: #757575;
$color-text: #5E5E5E;
$color-text-title: #5E5E5E;
$color-gray-title: #757575;
$color-disabled: #E0E0E0;

$color-neutral-black-10: #5E5E5E;
$color-neutral-black-20: #868484;
$color-neutral-black-30: #4A4647;
$color-neutral-black-40: #231F20;

$color-neutral-white-10: #FFF;
$color-neutral-white-20: #FCFCFC;
$color-neutral-white-30: #F7F7F6;
$color-neutral-white-40: #F0F0EF;

/* Utility Colors */
$color-table-header: #eaeaea;

$color-approved: #02A443;
$color-disapproved: #D0164E;
$color-pending: #D0775B;
$color-suspended: #5E5E5E;

/* V2 Colors */
$v2-neutral-0: #FFFFFF;
$v2-neutral-50: #F6F6F6;
$v2-neutral-200: #e9e9e9;
$v2-neutral-300: #C5C5C5;
$v2-neutral-400: #B4B4B4;
$v2-neutral-500: #6D6D6D;
$v2-neutral-800: #3A3A3A;
$v2-neutral-900: #1A1A1A;

$v2-primary-50: #E1F6FD;
$v2-primary-300: #55C7F4;
$v2-primary-500: #005B99;
$v2-primary-600: #003B5B;
$v2-primary-900: #001F3E;

$v2-secondary-50: #FAE8E7;
$v2-secondary-200: #F4B5B2;
$v2-secondary-300: #FE8067;
$v2-secondary-400: #FE6347;
$v2-secondary-500: #FE432A;
$v2-secondary-900: #BE2113;

$v2-background-color: #fff;
$v2-text: #5E5E5E;
$v2-disabled: #E0E0E0;

/* Gradients */
$gradient-blue-pink: linear-gradient(90deg, #4E02D1 0%, #FF069B 100%);
$gradient-red-orange: linear-gradient(90deg, #FF0059 0%, #FFB600 100%);
$gradient-blue-lightblue: linear-gradient(90deg, #0C02B6 0%, #57BBE6 100%);
$gradient-pink-purple: linear-gradient(90deg, #FE8067 1%, #FE8067 36%, #FE432A 100%);
$gradient-blue-darkblue: linear-gradient(90deg, rgba(0, 106, 198, 0.8) 0%, rgba(0, 106, 198, 0.3) 100%);
$gradient-black-gray: linear-gradient(90deg, #241F21 -13.67%, #999999 68.03%);


/* ============================================
   Typography
============================================= */

$font-number: 'Rethink Sans';
$font-primary: 'Rethink Sans';
$font-texts: 'Rethink Sans';


/* ============================================
   Breakpoints
============================================= */

$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1600px;


/* ============================================
   Spacing
============================================= */

$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-sm: 16px;
$spacing-md: 24px;
$spacing-lg: 32px;
$spacing-xl: 48px;
$spacing-xxl: 64px;

$container-size: 1100px;
