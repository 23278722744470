@import '../../../styles/variables.scss';

.contenta-header {
  background-color: $color-neutral-white-10;
  box-shadow: 0px 4px 4px 0px #00000026;
  z-index: 10;

  .header__content {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 70px;
    justify-content: space-between;
    left: -3px;
    margin: 0 auto;
    max-width: $container-size;
    padding: 0 20px;
    position: relative;

    @media (min-width: $breakpoint-lg) {
      padding: 0;
    }
  }

  .header__content-leftside {
    align-items: center;
    display: flex;
    gap: 20px;
    justify-content: center;
  }

  .header__button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
  }

  .header__content-rightside {
    align-items: center;
    display: flex;
    gap: 14px;
    justify-content: center;
  }

  .header__content-avatar {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  .header__content-user-container {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    justify-content: center;
  }

  .header__content-user {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  .header__content-username {
    color: $v2-primary-900;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .header__content-email {
    color: $v2-neutral-500;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}
