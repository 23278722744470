
.text-center {
  text-align: center;
}

.d-flex-row {
  display: flex;
  flex-flow: row nowrap;
}

.d-flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.align-center {
  align-items: center;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}


.pb-24 {
  padding-bottom: 24px;
}

.ml-8 {
  margin-left: 8px;
}

.pX-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.g-8, .gap-8 {
  gap: 8px;
}


.w-100 {
  width: 100%!important;
}

.h-auto {
  height: auto!important;
}

.borderless {
  border: none;
}

.rc-virtual-list-holder {
  overflow-y: scroll !important;
}

.rc-virtual-list-scrollbar-thumb {
  display: none;
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.flex-column {
  flex-flow: column nowrap;
}
